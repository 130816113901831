import { IGlobalModel, GlobalModel } from './global'
import { ITaskModel, TaskModel } from './task'
import { ITemplateModel, TemplateModel } from 'TemplateConstants/store/model'

export interface Model extends ITemplateModel {
  globalModel: IGlobalModel
  taskModel: ITaskModel
}

export const model: Model = {
  ...TemplateModel,
  globalModel: GlobalModel,
  taskModel: TaskModel
}
