import { BaseController } from './BaseController'

export interface ValidationsResponse {
  items?: ResultInterface
  error?: boolean
}

export interface ResultInterface {
  result: boolean
}

class ValidationsController extends BaseController {
  readonly url_hassession: string

  constructor () {
    super()

    this.url_hassession = `${this.apiHost}/${this.apiPath}/validations/hasSession`
  }

  async fetchHasSession (): Promise<ValidationsResponse> {
    const response = await this.useFetch('GET', `fetchSession`, `${this.url_hassession}`)

    if (this.checkStatusCode(response.status)) {
      const data = await response.json()
      return {
        items: data
      }
    } else {
      return {
        error: true
      }
    }
  }
}

const validationsController = new ValidationsController()
export default validationsController
