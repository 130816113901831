import { BaseController as BaseApiController } from 'TemplateConstants/controller'
import apiConfigs from 'Api/api-config'

// Override any methods from TemplateConstant BaseController here. Extend all other controllers off this controller
export class BaseController extends BaseApiController {
  readonly apiPath: any
  readonly apiPathKiosk: string
  readonly apiPathVisitingStudent: string

  constructor () {
    super()
    const isGlobalEnabled = localStorage.getItem('isGlobalEnabled') === 'true' ?? false
    const configKey = isGlobalEnabled ? 'global' : 'regional'

    this.apiPath = apiConfigs[configKey].paths.onboarding ?? ''
    this.apiPathKiosk = apiConfigs[configKey].paths.kiosk ?? ''
    this.apiPathVisitingStudent = apiConfigs[configKey].paths.visitingStudent ?? ''
  }

  checkStatusCode (status: number): boolean {
    return status >= 200 && status < 300
  }
}
