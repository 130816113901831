import { useState, useEffect } from 'react'
import { appInitialized } from '@mit/aws-react'
import { useStoreActions } from 'Store'
import { TrackController } from 'Api/controller'
import validationsController from 'Api/controller/ValidationsController'

interface UseAppInitializationReturn {
  initializeApp: () => void
}

export const useAppInitializer = (): UseAppInitializationReturn => {
  const [initializeApi, setInitializeApi] = useState(false)
  const { setFeature, setWelcome } = useStoreActions((actions: { globalModel: any }) => actions.globalModel)

  useEffect(() => {
    appInitialized({
      isLoading: true,
      hasAccess: false,
      hasError: false,
    });
  }, []);

  const initializeApp = async (): Promise<void> => {

    const trackContoller = new TrackController()

    let featurePromise = trackContoller.fetchFeature()
    let welcomePromise = trackContoller.fetchWelcome()
    let sessionPromise = validationsController.fetchHasSession()

    Promise.allSettled([featurePromise, welcomePromise, sessionPromise])
      .then(resp => {
        let hasAccess: boolean = false
        //featurePromise
        if (resp[0].status === 'fulfilled') {
          if (resp[0].value.items) {
            setFeature(resp[0].value.items)
          }
        }

        //welcomePromise
        if (resp[1].status === 'fulfilled') {
          if (resp[1].value.content) {
            setWelcome({
              content: resp[1].value.content,
              template: resp[1].value.template!
            })
          }
        }

        //sessionPromise
        if (resp[2].status === 'fulfilled') {
          if (resp[2].value.items?.result) hasAccess = true
        }
        appInitialized({
          isLoading: false,
          hasAccess: hasAccess,
          hasError: false
        })
      })
      .catch(() => {
        appInitialized({
          isLoading: false,
          hasAccess: false,
          hasError: true
        })
      })

    setInitializeApi(true)
  }

  return { initializeApp }
}
