import React, { FC, lazy } from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import { StoreProvider } from 'easy-peasy'
import { store } from 'Store'
import 'Scss/main.scss'
import { Initialization } from '@mit/aws-react'
import { useAppInitializer } from './Services/initilizer'
import appConfig from 'app-config-global'
import { Logout } from './TemplateConstants/components/Logout';

const WrappedApp: FC = () => {
  const { initializeApp } = useAppInitializer()
  const AppLazyLoad = lazy(async () => await import('Components/app/App'))
  return (
    <Switch>
      <Route exact path='/logout' component={Logout} />
      <Route path='/'>
        <Initialization
          appConfigData={appConfig}
          App={AppLazyLoad}
          appInitializer={initializeApp}
          loaderProps={{
            contactEmail: 'help@mit.edu',
            name: 'Onboarding',
            isLoading: true,
            type: 'Default',
            backgroundColor: '#20447D',
          }}
        />
      </Route>
    </Switch>
  )
}
ReactDOM.render(<React.StrictMode>
  <StoreProvider store={store}>
    <Router> <WrappedApp />
    </Router>
  </StoreProvider>
</React.StrictMode>
  , document.getElementById('root'))


