import { store } from 'Store'
import { AuthProvider } from '@mit/aws-react'
import apiConfigs from 'Api/api-config'
import appConfig from 'app-config-global'

export class BaseController {
  readonly appConfig
  readonly apiHost
  readonly apiPathDigitalId
  private readonly fetchController: Record<string, any>

  constructor () {
    this.appConfig = appConfig

    const isGlobalEnabled = localStorage.getItem('isGlobalEnabled') === 'true' ?? false
    const configKey = isGlobalEnabled ? 'global' : 'regional'

    this.apiHost = apiConfigs[configKey].apiHost ?? ''
    this.apiPathDigitalId = apiConfigs[configKey].paths.digitalId ?? ''

    this.fetchController = {}
  }

  private async getHeaders (
    method: string,
    overrideHeaders: Record<string, string> = {},
    file: boolean = false,
    impersonateKerb: string = ''
  ): Promise<Headers> {
    const token: string = await new AuthProvider(appConfig).getToken()

    const headers: { [key: string]: string } = {}

    if (!file) {
      headers['Content-Type'] = 'application/json'
      headers.Authorization = `Bearer ${token}`
    }

    if (impersonateKerb !== '') {
      headers['X-Impersonated-User'] = impersonateKerb
    }

    return new Headers({ ...headers, ...overrideHeaders })
  }

  async useFetch (
    method: string,
    fetchKey: string,
    url: string,
    body: any = null,
    overrideHeaders: Record<string, string> = {},
    file: boolean = false,
    impersonateKerb: string = ''
  ): Promise<any> {
    if (this.fetchController[fetchKey] !== null && this.fetchController[fetchKey] !== undefined) {
      this.fetchController[fetchKey].abort()
    }

    this.fetchController[fetchKey] = new AbortController()
    const { signal } = this.fetchController[fetchKey]

    const options: any = {
      method: method,
      headers: await this.getHeaders(method, overrideHeaders, file, impersonateKerb),
      signal
    }
    if (body !== null) {
      options.body = file ? body : JSON.stringify(body)
    }

    const response = await fetch(url, options)

    this.fetchController[fetchKey] = null

    return response
  }
}
