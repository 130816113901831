const apiConfigs = {
  "regional": {
    "apiHost": "https://api.mit.edu",
    "paths": {
      "onboarding": "onboarding-v1",
      "kiosk": "unicard-kiosk-v1",
      "visitingStudent": "visiting-student-v1",
      "digitalId": "digital-id-v1"
    }
  },
  "global": {
    "apiHost": "https://global.api.mit.edu",
    "paths": {
      "onboarding": "onboarding/v1",
      "kiosk": "unicard-kiosk/v1",
      "visitingStudent": "visiting-student/v1",
      "digitalId": "digital-id/v1"
    }
  }
};
export default apiConfigs;