import { CategoriesInterface, TrackInterface, TrackObject } from 'Api/controller/TrackController'
import { Action, action } from 'easy-peasy'

export interface State {
  track: TrackObject | null
  selectedCategory: CategoriesInterface | null
  selectedTask: TrackInterface | null
}
export interface Actions {
  setTrack: Action<this, TrackObject>
  setSelectedTask: Action<this, TrackInterface | null>
  setSelectedCategory: Action<this, CategoriesInterface>
}
// eslint-disable-next-line
export interface Thunks {}

export interface ITaskModel extends State, Actions, Thunks {}

export const TaskModel: ITaskModel = {
  selectedTask: null,
  selectedCategory: null,
  track: null,

  // Actions
  setSelectedTask: action((state, payload) => {
    state.selectedTask = payload
  }),
  setSelectedCategory: action((state, payload) => {
    state.selectedCategory = payload
  }),
  setTrack: action((state, payload) => {
    state.track = payload
  })
}
