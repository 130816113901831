import { FeatureInterface, WelcomeInterface } from 'Api/controller/TrackController'
import { Action, action } from 'easy-peasy'

export interface State {
  auth: object
  isLoading: boolean
  feature: FeatureInterface[]
  welcome: WelcomeInterface
}
export interface Actions {
  setAuth: Action<this, object>
  setIsLoading: Action<this, boolean>
  setFeature: Action<this, FeatureInterface[]>
  setWelcome: Action<this, WelcomeInterface>
}
// eslint-disable-next-line
export interface Thunks {}

export interface IGlobalModel extends State, Actions, Thunks {}

export const GlobalModel: IGlobalModel = {
  auth: {},
  isLoading: true,
  feature: [],
  welcome: {
    content: '',
    template: 'full'
  },

  // Actions
  setAuth: action((state, payload) => {
    state.auth = payload
  }),
  setIsLoading: action((state, payload) => {
    state.isLoading = payload
  }),
  setFeature: action((state, payload) => {
    state.feature = payload
  }),
  setWelcome: action((state, payload) => {
    state.welcome = payload
  })
}
